import React, { useEffect, useState } from 'react'
import CTA from '../../CTA'

import './maincalculator.scss'
export const MainCalculator = ({ setResults }) => {
  const [population, setPopulation] = useState(100)
  const [confidence, setConfidence] = useState('95')
  const [moe, setMoe] = useState(5)
  const [zScore, setZScore] = useState(2.58)
  const [sampleSize, setSamplSize] = useState(0)

  const calculateSample = () => {
    const zScoreSquared = Math.pow(parseFloat(zScore), 2)
    const eSquared = Math.pow(parseFloat(moe) / 100, 2)
    const N = parseFloat(population)
    const numerator = (zScoreSquared * 0.25) / eSquared
    const denominator = 1 + (zScoreSquared * 0.25) / (eSquared * N)
    setSamplSize(parseInt(Math.round(numerator / denominator)))
    setResults(parseInt(Math.round(numerator / denominator)))
  }

  useEffect(() => {
    let x
    if (confidence === '99.9') {
      x = 3.291
    } else if (confidence === '99.5') {
      x = 2.807
    } else if (confidence === '99') {
      x = 2.576
    } else if (confidence === '95') {
      x = 1.96
    } else if (confidence === '90') {
      x = 1.645
    } else if (confidence === '85') {
      x = 1.44
    } else if (confidence === '80') {
      x = 1.282
    }
    setZScore(x)
    calculateSample()
  }, [zScore, population, confidence, moe])
  return (
    <div className="main-body-container text-white">
      <div className="row  jt-cn w-100 mt-5">
        <span>Calculate Your Sample Size</span>
      </div>
      <div className="row sp-ev w-75 ">
        <div className="col">
          <div className="col mt-20 ">
            <label>Population Size</label>
            <input
              type={`number`}
              className="calc-input"
              onChange={(event) => {
                setPopulation(event.target.value)
              }}
              value={population}
            />
          </div>
          <div className="col mt-20 w-100">
            <span>Confidence Level (%)</span>
            <select
              className="calc-input"
              id="confidence"
              name="confidence"
              onChange={(event) => {
                setConfidence(event.target.value)
              }}
              value={confidence}
            >
              <option value="80">80</option>
              <option value="85">85</option>
              <option value="90">90</option>
              <option value="95">95</option>
              <option value="99">99</option>
              <option value="99.5">99.5</option>
              <option value="99.9">99.9</option>
            </select>
            {/* <input
              type={`number`}
              className="calc-input"
              onChange={(event) => {
                setConfidence(event.target.value)
              }}
              value={confidence}
            /> */}
          </div>
          <div className="col mt-20 w-100">
            <span>Margin of Error (%)</span>
            <input
              type={`number`}
              className="calc-input"
              onChange={(event) => {
                setMoe(event.target.value)
              }}
              value={moe}
            />
          </div>
        </div>
      </div>
      <div className="row mt-5 sp-ev w-75">
        <CTA type={`secondary-v1`} height="46px" width={`63%`} size="0.8em">
          {`Calculate`}
        </CTA>
      </div>
    </div>
  )
}
