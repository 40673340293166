import React, { useState } from 'react'
import './calculator.scss'
import CalculatorImage from '../../images/calculator/calculatorImage.png'
import { MainCalculator } from './MainCalulator/MainCalculator'

export const CalculatorComp = () => {
  const [calculatorResults, setCalculatorResults] = useState(0)
  return (
    <div className="calc-body-container mx-auto w-70">
      <div className="row mt-5 sp-ev mobile-col">
        <div className="col ">
          <MainCalculator setResults={setCalculatorResults} />
        </div>
        <div className="col">
          <div className="row">
            <img src={CalculatorImage} alt="cal_img" width={150} />
          </div>
          <div className="text-center">
            <h5 className='mobile-h5'>Your Sample Size is</h5>
            <h1 className="text-pink font-size-40">{calculatorResults !== NaN ? calculatorResults : "No Value"}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
