import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import richTextRenderer from '../../utils/richTextRenderer'
import './sample.scss'
import { CenteredHero } from '../../components/Hero/CenteredHero'
import Hero from '../../components/Hero/Hero'
import PersonInFrontOfCalc from '../../images/calculator/personCalculator.png'
import Footer from '../../components/Footer'
import SampleSizeCTA from '../../Contentful/SampleSize'
import { CalculatorInfoBody } from '../../components/CalculatorInfoBody/CalculatorInfoBody'
import { CalculatorComp } from '../../components/Calculator/CalculatorComp'
import { QueryGraphql } from '../../utils/graphQlQuery'

const SampleSizeCalculator = () => {
  let queryData = QueryGraphql()

  const {
    allContentfulHero: { nodes },
  } = queryData

  // const mainData = desc.find((subject) => subject.title === 'Akta Pricing')
  const mainData = nodes
    .filter((val) => val.header === 'Akta Calculator')
    .filter((val) => {
      const { desc: mainDesc } = val
      return mainDesc ?? val
    })

  const results = mainData[0]
  console.log({ results })

  const { header, mainDescription, title: info, backgroundColor } = results

  const fullDescription = mainDescription.map((val, ind) => {
    let description = {}
    description.info = val
    return description
  })

  let data = useStaticQuery(graphql`
    query sampleSizeData {
      allContentfulSampleSize {
        nodes {
          id
          contentfulid
          content {
            raw
          }
        }
      }
    }
  `)

  const {
    allContentfulSampleSize: { nodes: sampleSizeData },
  } = data

  const aboveArray = sampleSizeData.filter(
    (solution) => solution.contentfulid === 'above'
  )

  const belowArray = sampleSizeData.filter(
    (solution) => solution.contentfulid === 'below'
  )

  const { raw: aboveRaw } = aboveArray[0].content
  const { raw: belowRaw } = belowArray[0].content

  const [population, setPopulation] = useState(100)
  const [confidence, setConfidence] = useState('95')
  const [moe, setMoe] = useState(5)
  const [zScore, setZScore] = useState(2.58)
  const [sampleSize, setSamplSize] = useState(0)

  const calculateSample = () => {
    const zScoreSquared = Math.pow(parseFloat(zScore), 2)
    const eSquared = Math.pow(parseFloat(moe) / 100, 2)
    const N = parseFloat(population)
    const numerator = (zScoreSquared * 0.25) / eSquared
    const denominator = 1 + (zScoreSquared * 0.25) / (eSquared * N)
    setSamplSize(parseInt(Math.round(numerator / denominator)))
  }

  useEffect(() => {
    calculateSample()
  }, [])

  useEffect(() => {
    let x
    if (confidence === '99.9') {
      x = 3.291
    } else if (confidence === '99.5') {
      x = 2.807
    } else if (confidence === '99') {
      x = 2.576
    } else if (confidence === '95') {
      x = 1.96
    } else if (confidence === '90') {
      x = 1.645
    } else if (confidence === '85') {
      x = 1.44
    } else if (confidence === '80') {
      x = 1.282
    }
    setZScore(x)
    calculateSample()
  }, [zScore, population, confidence, moe])

  return (
    <div>
      <div>
        <Hero
          title={info}
          buttonTitle={`Get Started`}
          isButton={true}
          description={fullDescription}
          image={PersonInFrontOfCalc}
          backgroundColor={backgroundColor}
          titleColor={`text-matte`}
        />
      </div>

      <div className="calculator">
        <h1>Sample Size Calculator</h1>

        <div>{richTextRenderer(JSON.parse(aboveRaw))}</div>
        {/* <div>
          <h2>Calculate Your Sample Size</h2>
          <div className="calcInputs">
            <div>
              <label htmlFor="population">Population Size</label>
              <input
                min="1"
                type="number"
                id="population"
                name="population"
                onChange={(event) => {
                  setPopulation(event.target.value)
                }}
                value={population}
              />
            </div>
            <div>
              <label htmlFor="confidence">Confidence Level (%)</label>
              <select
                id="confidence"
                name="confidence"
                onChange={(event) => {
                  setConfidence(event.target.value)
                }}
                value={confidence}
              >
                <option value="80">80</option>
                <option value="85">85</option>
                <option value="90">90</option>
                <option value="95">95</option>
                <option value="99">99</option>
                <option value="99.5">99.5</option>
                <option value="99.9">99.9</option>
              </select>
            </div>
            <div>
              <label htmlFor="moe">Margin of Error (%)</label>
              <input
                min="1"
                type="number"
                id="moe"
                name="moe"
                onChange={(event) => {
                  setMoe(event.target.value)
                }}
                value={moe}
              />
            </div>
          </div>
          <>
            <span className="label">Sample Size: </span>
            <span className="number">{sampleSize && sampleSize}</span>
          </>
        </div>
        <div></div>

        <div></div>

        <div>
          <Link to="/take-a-survey">
            <button>
              <span>Get Started</span>
            </button>
          </Link>
        </div> */}
      </div>
      <div>
        <CalculatorComp />
      </div>
      <div>
        <CalculatorInfoBody
          belowData={richTextRenderer(JSON.parse(belowRaw))}
        />
      </div>
      <div>
        <SampleSizeCTA />
        <Footer />
      </div>
    </div>
  )
}

export default SampleSizeCalculator
